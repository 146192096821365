<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <page-header>{{ $t('features.title') }}</page-header>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <page-header-subtitle>{{ $t('features.subtitle') }}</page-header-subtitle>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="10" md="9" lg="8">

        <v-row class="pt-2 pt-md-7 pb-7">
          <v-col class="d-flex" cols="12" md="6" v-for="(feature, index) in features" :key="index">
            <feature-card :feature="feature" show-popular image-height="190" text-height="240"></feature-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageHeaderSubtitle from '@/components/PageHeaderSubtitle';
import featuresData from '@/data/features.data';
import FeatureCard from '@/views/features/FeatureCard';

export default {
  components: {
    FeatureCard,
    PageHeader,
    PageHeaderSubtitle,
  },
  computed: {
    features() {
      return featuresData;
    }
  }
}
</script>

<style scoped>
</style>
